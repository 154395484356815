import React from "react"
import { Link } from "gatsby"
import Seo from "./seo"

const Partners = ({ partners }) => {
  return (
    <div className="w-full h-full">
      <div className="container mx-auto grid gap-5 grid-cols-1 sm:grid-cols-2 md:grid-cols-3 px-5 md:px-0">
        {partners.map((partner, i) => {
          return (
            <Link
              to={`/partner/${partner.node.slug}`}
              key={`partner__${partner.node.slug}`}
            >
              <div className="bg-white rounded-xl shadow-xl border-0border-gray-100">
                <div className="p-4">
                  <div className="w-full relative rounded-lg bg-gray-100">
                    <img src={partner.node.headerImage.url} alt="" className="w-full h-72 object-cover rounded-lg border-0 border-gray-200"/>
                    <img src={partner.node.partnerLogo.url} alt="" className="absolute top-0 h-20 w-20 object-contain bg-white p-2 m-2 border-0 border-gray-200 shadow-md rounded-lg"/>
                  </div>
                  
                  <div className="flex justify-between">
                    <div className="mr-4">
                      <p className="font-bold text-xl leading-tight font-medium text-gray-800 text-left mt-2">
                        {partner.node.mainSubHeading}
                      </p>
                      <p className="font-medium text-sm text-gray-600 font-medium text-left mt-1">
                        {partner.node.mainHeading.substring(0, 45)}...
                      </p>
                    </div>
                    <div className="text-right">
                      <span className="inline-block font-medium bg-gray-200 text-gray-900 text-pill py-1 px-2 mt-2 rounded-lg">
                        {partner.node.partnerPill}
                      </span>
                      <div className="btn-secondary-sm w-24 text-center mt-3">
                        <span className="w-full">View deal</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Link>
          )
        })}
      </div>
    </div>
  )
}

export default Partners
